/// hero-section
.hero-section {
    position: relative;

    .top-section {
        .title {
            padding-top: 130px;
            font-size: 3.2em !important;
            color: white;
        }

        background-color: #596776;
        height: 600px;
    }

    .image {
        position: absolute;
        top: 70%;
    }

    .banner-img-section {
        margin-top: -300px;

        img {
            width: 70%;
        }
    }

    @media screen and (max-width: 480px) {
        .banner-img-section {
            margin-top: -260px;

            img {
                width: 100%;
            }
        }
    }

}

.start-free {
    background: linear-gradient(45deg, #fe60a1 0%, #ff8765 100%) !important;
    color: white !important;
}

.features-section {
    margin-top: 150px;

    .section-heading {
        h2 {
            font-size: 2.5em;
            color: #59687c;
            font-weight: 700;
        }

        p {
            font-size: 16px;
        }
    }

    .features-card {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        min-height: 200px;
        padding: 6px;
        border-radius: 6px;
        margin-bottom: 10px;

        h4 {
            padding-top: 20px;
            text-align: center;
        }
    }
}

.integration-section {
    margin-top: 50px;
    padding: 100px 10px;
    background: linear-gradient(45deg, #8089ff 0%, #54ceff 100%);
}

.show-case-section {
    background-color: white;
    padding-top: 100px;
    padding-bottom: 100px;

    .start-free {
        background: linear-gradient(45deg, #fe60a1 0%, #ff8765 100%);
        color: white;
    }
}

.support-section {
    background: linear-gradient(45deg, #8089ff 0%, #54ceff 100%);
    padding: 100px 0px;

    .heading {
        font-size: 25px;
        color: white;
        font-weight: 700;
    }
}

.testimonial-section {
    background: linear-gradient(45deg, #8089ff 0%, #54ceff 100%);
}

.footer-section {
    .ui-footer {
        position: relative;
        z-index: 2;
    }

    .ui-footer .footer-bg {
        padding: 8rem 0;
    }

    .ui-footer .heading,
    .ui-footer .paragraph {
        margin-top: 0;
    }

    .ui-footer .heading {
        margin-bottom: 1rem;
    }

    .ui-footer .paragraph {
        margin-bottom: 0;
    }

    .ui-footer .actions {
        margin-top: 2.25rem;
    }

    .ui-footer .actions.social-links {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }

    @media (max-width: 739px) {
        .ui-footer .footer-col {
            margin-bottom: 4rem;
        }
    }

    .ui-footer .footer-copyright {
        padding: 1.25rem 0;
    }

    .ui-footer .footer-copyright p {
        font-size: .9rem;
        margin-bottom: 0;
        line-height: 40px;
    }

    .ui-footer .footer-copyright ul.footer-nav {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        height: 40px;
    }

    @media (max-width: 739px) {
        .ui-footer .footer-copyright ul.footer-nav {
            justify-content: center;
        }
    }

    .ui-footer .footer-copyright ul.footer-nav li {
        margin-bottom: 0;
        margin-left: 1rem;
    }

    .ui-footer .footer-copyright ul.footer-nav li:nth-child(1) {
        margin-left: 0;
    }

    .ui-footer .download-section {
        padding-top: 4rem;
    }

    @media (min-width: 481px) {
        .ui-footer .heading {
            margin-bottom: 1.25rem;
        }
    }

    @media (min-width: 740px) {
        .ui-footer .heading {
            margin-bottom: 1.5rem;
        }
    }

    @media (min-width: 992px) {
        .ui-footer .heading {
            margin-bottom: 1.75rem;
        }
    }

    .ui-footer .footer-heading {
        text-transform: uppercase;
    }

    .ui-footer ul {
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;
    }

    .ui-footer ul.footer-nav li {
        margin-bottom: .35rem;
    }

    .ui-footer ul.footer-nav li a {
        display: block;
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 600;
        font-size: .95rem;
    }

    .ui-footer .footer-about img {
        height: 40px;
        width: auto;
        margin-bottom: .15rem;
    }

    .ui-footer .footer-about p {
        font-size: .9rem;
    }

    .ui-footer.subscribe-footer {
        padding-top: 6rem;
    }

    .ui-footer.subscribe-footer .form-card {
        max-width: 600px;
        margin: -14rem auto 2rem auto;
        text-align: center;
    }

    .ui-footer.subscribe-footer .form-card .heading {
        margin-bottom: 1rem;
    }

    .input-group input.form-control {
        padding: 8px 1rem 8px 1.5rem;
        border-top-left-radius: 2rem;
        border-bottom-left-radius: 2rem;
    }

    .input-group .btn {
        padding: 0 1rem;
        line-height: 35px;
    }

    .ui-footer.contact-footer {
        padding-top: 6rem;
    }

    .ui-footer.contact-footer .form-card {
        margin: 0 0 2rem 0;
    }

    .ui-footer.contact-footer .form-card .heading {
        margin-bottom: 0;
    }

    .ui-footer.contact-footer .form-card.form-card-1 {
        margin: -14rem 0 2rem 0;
    }

    @media (min-width: 740px) {
        .ui-footer.contact-footer .form-card {
            margin: -14rem 0 2rem 0;
        }
    }

    .ui-footer.contact-footer .form-card .ui-icon-block {
        margin-bottom: 1rem;
    }

    .ui-footer.maps-footer,
    .ui-footer.contact-footer.maps-footer {
        margin-top: 0;
        padding-top: 0;
    }

    .ui-map {
        transform: translateZ(0px);
        height: 440px;
    }

    .ui-footer.ui-waves {
        margin-top: -2rem;
    }

    .ui-footer.ui-waves.subscribe-footer,
    .ui-footer.ui-waves.contact-footer {
        padding-top: 0;
    }

    .ui-footer.ui-waves .container {
        position: relative;
        z-index: 2;
    }

    .ui-footer.ui-waves .footer-bg {
        padding: 16rem 0 8rem 0;
    }

    .ui-footer.ui-waves::after {
        display: block;
        content: '';
        height: 100%;
        // background: url(../assets/img/svg-layers/waves2.svg) top no-repeat;
        background-size: contain;
        position: absolute;
        top: -5px;
        left: -1px;
        right: -1px;
        z-index: 1;
    }

    .ui-footer.mini-footer .footer-bg {
        padding: 2rem 0;
    }

    .ui-footer.mini-footer .footer-logo img {
        height: 40px;
        width: auto;
    }

    .ui-footer.mini-footer .footer-copyright {
        text-align: right;
    }
}




.navbar {
    display: block;
    position: fixed;
    width: 100%;
    font-family: "Source Sans Pro", sans-serif;
    align-items: inherit;
    box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
    transition: box-shadow 0.35s ease-out, background-color 0.35s ease-out;
    z-index: 9;
}

@media (max-width: 481px) {
    .navbar {
        min-height: 50px;
    }
}

.navbar .navbar-brand {
    position: relative;
    font-size: 1.4rem;
}

.navbar .navbar-brand img {
    height: 30px;
    width: auto;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -15px;
    transition: opacity 0.35s ease-out;
}

@media (min-width: 481px) {
    .navbar .navbar-brand img {
        height: 36px;
        margin-top: -18px;
    }
}

@media (min-width: 740px) {
    .navbar .navbar-brand img {
        height: 40px;
        margin-top: -20px;
    }
}

@media (max-width: 481px) {
    .navbar .navbar-brand {
        height: 50px;
    }
}

@media (max-width: 991px) {
    .navbar .navbar-brand {
        flex: 1;
    }
}

.navbar a {
    font-size: .95rem;
    font-weight: 600;
}

@media (min-width: 740px) {
    .navbar a {
        font-size: 1rem;
    }
}

.navbar .nav {
    display: flex;
    flex-direction: row;
}

.navbar .nav>li>a {
    color: #414c5a;
    padding-left: 10px;
    padding-right: 10px;
}

@media (min-width: 992px) {
    .navbar .nav>li>a {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.navbar .nav>li>a:hover,
.navbar .nav>li>a:active {
    color: #8089ff;
}

.navbar .nav>li.active>a {
    color: #8089ff;
}

.navbar.navbar-dark .nav>li.active>a {
    color: #fff;
}

.navbar.navbar-dark .nav>li>a {
    color: #fff;
}

.navbar .dropdown-toggle:after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .200em;
    vertical-align: .155em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

.navbar .dropdown-menu {
    background-color: transparent;
}

@media (min-width: 740px) {
    .navbar .dropdown-menu {
        background-color: #FFF;
        box-shadow: 0 7px 15px rgba(0, 9, 128, 0.05), 0 12px 28px rgba(0, 9, 128, 0.075);
    }
}

.navbar .dropdown-menu a {
    font-size: .9rem;
}

.navbar .btn {
    // transition: background 0.35s ease-out;
    padding: .4rem 1rem;
    margin: 9px 0 9px 1rem;
    color: #FFF;
}

@media (min-width: 481px) {
    .navbar .btn {
        margin: 13px 0 13px 1.25rem;
    }
}

@media (min-width: 740px) {
    .navbar .btn {
        margin: 13px 0 13px 1.5rem;
    }
}

.navbar .ui-navigation {
    flex: 1;
}

@media (min-width: 768px) {
    .navbar .ui-navigation {
        display: flex;
        align-items: center;
    }

    .navbar .ui-navigation.navbar-center {
        justify-content: center;
    }

    .navbar .ui-navigation.navbar-right {
        justify-content: flex-end;
    }
}

@media (min-width: 992px) {
    .navbar .ui-navigation {
        height: 60px;
    }
}

@media (min-width: 740px) {
    .navbar .ui-navigation.navbar-center .navbar-nav {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        float: none;
    }
}

.navbar .ui-navigation.navbar-right {
    display: flex;
    justify-content: flex-end;
}

.navbar.transparent,
.navbar.transparent.dark,
.navbar.transparent.light,
.navbar.transparent.bg-primary {
    box-shadow: none;
    background-color: transparent;
}

.navbar.transparent:not(.text-dark) .navbar-brand,
.navbar.transparent.dark:not(.text-dark) .navbar-brand,
.navbar.transparent.light:not(.text-dark) .navbar-brand,
.navbar.transparent.bg-primary:not(.text-dark) .navbar-brand {
    color: #FFF;
}

.navbar.transparent:not(.text-dark) .navbar-nav>li>a,
.navbar.transparent.dark:not(.text-dark) .navbar-nav>li>a,
.navbar.transparent.light:not(.text-dark) .navbar-nav>li>a,
.navbar.transparent.bg-primary:not(.text-dark) .navbar-nav>li>a {
    color: #FFF;
}

.navbar.transparent:not(.text-dark) .navbar-nav>li>a:hover,
.navbar.transparent.dark:not(.text-dark) .navbar-nav>li>a:hover,
.navbar.transparent.light:not(.text-dark) .navbar-nav>li>a:hover,
.navbar.transparent.bg-primary:not(.text-dark) .navbar-nav>li>a:hover {
    color: #FFF;
}

.navbar.transparent:not(.text-dark) .navbar-nav>li.active>a,
.navbar.transparent.dark:not(.text-dark) .navbar-nav>li.active>a,
.navbar.transparent.light:not(.text-dark) .navbar-nav>li.active>a,
.navbar.transparent.bg-primary:not(.text-dark) .navbar-nav>li.active>a {
    color: #FFF;
}

.navbar.transparent:not(.text-dark) .btn,
.navbar.transparent.dark:not(.text-dark) .btn,
.navbar.transparent.light:not(.text-dark) .btn,
.navbar.transparent.bg-primary:not(.text-dark) .btn {
    background: #FFF;
    color: #8089ff;
}

.navbar .ui-variable-logo .logo-default {
    opacity: 1;
}

.navbar .ui-variable-logo .logo-transparent {
    opacity: 0;
}

.navbar.transparent .ui-variable-logo .logo-default {
    opacity: 0;
}

.navbar.transparent .ui-variable-logo .logo-transparent {
    opacity: 1;
}

@media (max-width: 991px) {
    .ui-navigation {
        display: none;
        clear: both;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
    }
}

@media (max-width: 991px) {
    .navbar .ui-navigation .nav.navbar-nav {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        text-align: center;
    }

    .navbar .ui-navigation .nav.navbar-nav li {
        flex: 1;
        width: 100%;
        opacity: 0;
    }

    .navbar .ui-navigation .nav.navbar-nav a {
        font-size: 1rem;
        line-height: 2;
        display: block;
    }

    .navbar .ui-navigation .nav.navbar-nav .dropdown-menu {
        box-shadow: none;
        text-align: center;
        background-color: #f6f7f8;
    }

    .navbar .ui-navigation .nav.navbar-nav .dropdown-menu li {
        padding: 0;
    }

    .navbar .ui-navigation .nav.navbar-nav .dropdown-menu li a {
        color: #414c5a;
    }

    .navbar .ui-navigation .nav.navbar-nav .dropdown-menu li.active a {
        background-color: #8089ff;
        display: block;
        color: #fff;
    }
}

.mobile-nav-active .navbar-nav .dropdown-menu {
    padding: 0 0 .75rem 0;
}

.mobile-nav-active .navbar-nav.nav>li>a:hover {
    background-color: rgba(56, 58, 73, 0.1);
}

.ui-mobile-nav-toggle {
    margin-left: 1rem;
}

@media (min-width: 481px) {
    .ui-mobile-nav-toggle {
        margin-left: 2rem;
    }
}

@media (min-width: 992px) {
    .ui-mobile-nav-toggle {
        display: none;
    }
}

.ui-mobile-nav-toggle>div {
    position: relative;
    width: 24px;
    height: 16px;
    margin: 22px 0;
}

.ui-mobile-nav-toggle>div>span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #414c5a;
    border-radius: 4px;
    left: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: 0.25s ease-out;
}

.ui-mobile-nav-toggle>div>span:nth-child(1) {
    top: 0px;
}

.ui-mobile-nav-toggle>div>span:nth-child(2),
.ui-mobile-nav-toggle>div>span:nth-child(3) {
    top: 5.83333px;
}

.ui-mobile-nav-toggle>div>span:nth-child(4) {
    top: 11.66667px;
}

@media (max-width: 481px) {
    .ui-mobile-nav-toggle>div {
        margin: 17px 0;
    }
}

.ui-mobile-nav-toggle.active>div>span:nth-child(1) {
    top: 9.9999999px;
    width: 0%;
    left: 50%;
}

.ui-mobile-nav-toggle.active>div>span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.ui-mobile-nav-toggle.active>div>span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.ui-mobile-nav-toggle.active>div>span:nth-child(4) {
    top: 9.9999999px;
    width: 0%;
    left: 50%;
}

.transparent .ui-mobile-nav-toggle>div>span,
.navbar-dark .ui-mobile-nav-toggle>div>span {
    background-color: #FFF;
}