@font-face {
    font-family: 'Royce-Light';
    src: url('./Royce-Light.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Royce-Regular';
    src: url('./Royce-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Royce-Medium';
    src: url('./Royce-Medium.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}